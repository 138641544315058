<template>
  <v-container fluid>
    <v-row class="mb-2">
      <v-col class="text-center text-h4 col-12">Финансовая отчетность</v-col>
    </v-row>
    <CreateInvoice />
    <Invoices />
  </v-container>
</template>

<script>
import CreateInvoice from "@/views/pages/Invoices/CreateInvoice";
import Invoices from "@/views/pages/Invoices/Invoices.vue";
export default {
  name: "Index",
  components: {
    CreateInvoice,
    Invoices
  }
};
</script>

<style scoped></style>
