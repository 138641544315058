<template>
  <v-row>
    <v-col>
      <v-card>
        <v-card-title>
          Последние инвойсы
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="invoices"
            @update:page="loadPage"
            :server-items-length="countRow"
            :loading="loading"
            loading-text="Загрузка... Пожалуйста подождите"
            :items-per-page="25"
          >
            <template v-slot:item.user="{ item }">
              <router-link tag="div" :to="`/users/${item.cards.id}`">
                <v-btn color="primary"> {{ item.cards.nick }}</v-btn>
              </router-link>
            </template>
            <template v-slot:item.amount="{ item }">
              {{ (item.amount / 100).toFixed(2) }}
            </template>
            <template v-slot:item.amountUsdt="{ item }">
              {{ (item.amountUsdt / 100).toFixed(2) }}
            </template>
            <template v-slot:item.createdAt="{ item }">
              {{ item.createdAt | moment("YYYY-MM-DD HH:mm:ss") }}
            </template>
            <template v-slot:item.description="{ item }">
              <span>{{ item.description }}</span>
              <!-- Добавляем проверку для описания -->
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon @click="deleteInvoices(item.id)">
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import InvoicesService from "@/services/invoices.service";
export default {
  name: "Invoices",
  data: () => ({
    headers: [
      { text: "ID", value: "id" },
      { text: "Оператор ", value: "user" },
      { text: "Тип", value: "invoiceTypes.value" },
      { text: "Описание", value: "description" },
      { text: "Сумма", value: "amount" },
      { text: "Сумма USDT", value: "amountUsdt" },
      { text: "Валюта в фиате", value: "currency" },
      { text: "Ссылка на чек", value: "fileUrl" },
      { text: "Дата создания", value: "createdAt" },
      { text: "Действия", value: "actions" }
    ],
    search: ""
  }),
  computed: {
    ...mapGetters("invoices", ["invoices", "loading", "countRow"])
  },
  watch: {
    search(word) {
      if (word.length > 3) {
        this.findMany({
          include: {
            cards: true,
            invoiceTypes: true // Make sure to include invoiceTypes here too
          },
          orderBy: {
            id: "desc"
          },
          where: {
            description: {
              contains: word
            }
          },
          take: 15
        });
      } else {
        this.loadPage();
      }
    }
  },
  methods: {
    ...mapActions("invoices", ["findMany", "count"]),
    loadPage(page) {
      this.loadInvoices(page);
    },
    loadInvoices(page = 1) {
      this.count();
      this.findMany({
        orderBy: {
          id: "desc"
        },
        include: {
          cards: true,
          invoiceTypes: true
        },
        skip: (page - 1) * 15,
        take: 15
      });
    },
    deleteInvoices(id) {
      InvoicesService.delete({
        where: {
          id: parseInt(id)
        }
      }).then(
        () => {
          this.$noty.success("Успешно");
          this.loadInvoices();
        },
        error => this.$noty.error(error.response.message)
      );
    }
  },
  created() {
    this.loadInvoices();
  }
};
</script>

<style scoped></style>
