<template>
  <v-row>
    <v-col>
      <v-tabs v-model="tab">
        <v-tab>Создать инвойс</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card>
            <v-card-title>Создание инвойса</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="4">
                  <v-select
                    label="Тип"
                    :items="types"
                    item-text="value"
                    item-value="id"
                    v-model="type"
                    outlined
                  />
                </v-col>
                <v-col cols="12" sm="4">
                  <v-select
                    label="Валюта в фиате"
                    :items="currencies"
                    item-text="value"
                    item-value="id"
                    v-model="currency"
                    outlined
                  />
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    label="Сумма в фиате"
                    type="number"
                    v-model="amountFiat"
                    outlined
                  />
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    label="Сумма в USDT"
                    type="number"
                    v-model="amountUSDT"
                    outlined
                  />
                </v-col>
                <v-col cols="12" sm="4">
                  <v-menu
                    ref="menu"
                    v-model="firstMenu"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="createdAt"
                        label="Дата инвойса"
                        outlined
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-card>
                      <v-date-picker v-model="date" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="firstMenu = false"
                          >Cancel</v-btn
                        >
                        <v-btn text color="primary" @click="selectDate"
                          >OK</v-btn
                        >
                      </v-date-picker>
                      <v-divider></v-divider>
                    </v-card>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    label="Уточнение инвойса"
                    v-model="description"
                    outlined
                  />
                </v-col>
                <v-col cols="12" sm="4">
                  <v-btn
                    @click="loadCheckModal = true"
                    color="primary"
                    x-large
                    outlined
                    class="mr-2"
                    >Загрузка чеков</v-btn
                  >
                  <v-dialog v-model="loadCheckModal" max-width="400">
                    <v-card>
                      <v-card-title class="headline"
                        >Загрузка чеков</v-card-title
                      >
                      <v-card-text>
                        <div style="display: flex">
                          <v-file-input
                            v-model="file"
                            label="Выбирите файл"
                            accept="image/*"
                            outlined
                          ></v-file-input>
                          <v-btn color="primary" @click="addCheckToList"
                            >+</v-btn
                          >
                        </div>
                        <h3 style="color: black">Выбраны файлы:</h3>
                        <hr class="mt-2" />
                        <div v-for="(check, key) in checksList" :key="key">
                          <div style="display: flex">
                            <div
                              style="overflow: hidden; width: 280px; white-space: nowrap; text-overflow: ellipsis;"
                            >
                              {{ check.name }}
                            </div>
                            <v-btn
                              color="red"
                              style="margin-left: auto;"
                              @click="deleteCheckFromList(key)"
                              >-</v-btn
                            >
                          </div>
                          <hr />
                        </div>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="loadCheckModal = false">
                          Ок
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-btn
                    color="primary"
                    x-large
                    outlined
                    @click="createInvoice"
                  >
                    Создать инвойс
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-col>
  </v-row>
</template>

<script>
import InvoicesServices from "@/services/invoices.service";
import InvoiceTypesServices from "@/services/invoice.types.service";
import { mapActions, mapGetters } from "vuex";
import imageCompression from "browser-image-compression";
import moment from "moment";

export default {
  name: "CreateInvoices",
  data: () => ({
    tab: 0,
    types: [],
    currencies: [
      { id: 1, value: "RUB" },
      { id: 2, value: "USD" },
      { id: 3, value: "EUR" },
      { id: 4, value: "UAH" },
      { id: 5, value: "KZT" },
      { id: 7, value: "AZN" },
      { id: 8, value: "KGS" },
      { id: 9, value: "UZS" },
      { id: 10, value: "TJS" }
    ],
    type: 1,
    currency: 1,
    description: "", // Инициализация description
    amountFiat: 0,
    amountUSDT: 0,
    date: new Date().toISOString().substr(0, 10), // Изначально только дата
    createdAt: moment().format("YYYY-MM-DD HH:mm:ss"), // Формат даты и времени для инвойса
    firstMenu: false,
    file: null,
    loadCheckModal: false,
    checksList: []
  }),
  computed: {
    ...mapGetters("user", ["user", "getProfileAdmin"])
  },
  methods: {
    ...mapActions("invoices", ["findMany"]),

    // Объединение выбранной даты и времени
    selectDate() {
      this.firstMenu = false;

      // Сохраняем текущее время из createdAt
      const currentTime = moment(this.createdAt).format("HH:mm:ss");

      // Объединяем выбранную дату с текущим временем
      this.createdAt = moment(
        `${this.date} ${currentTime}`,
        "YYYY-MM-DD HH:mm:ss"
      ).format("YYYY-MM-DD HH:mm:ss");
    },

    async createInvoice() {
      const fileList = await this.generatedBase64List(this.checksList);
      // console.log(this.getProfileAdmin);
      if (fileList.length) {
        InvoicesServices.createInvoice({
          data: {
            type: this.type,
            cardId: this.getProfileAdmin,
            currency: this.currency,
            description: this.description,
            amount: parseInt(this.amountFiat) * 100,
            amountUsdt: parseInt(this.amountUSDT) * 100,
            createdAt: new Date(this.createdAt),
            checksList: fileList
          }
        }).then(
          data => {
            this.checksList = [];
            this.file = null;
            if (data.success) {
              // Refetch invoices with related data included to ensure the newly created invoice displays properly
              this.$noty.success("Инвойс успешно создан");
            } else {
              this.$noty.error(data.err);
            }
          },
          error => this.$noty.error(error.response.data.message)
        );
      } else {
        if (this.checksList.length) {
          this.$noty.error("Размер pdf файлов превышает лимит");
        } else {
          this.$noty.error("Нет загруженных чеков");
        }
      }
    },
    async fileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = () => reject(new Error("Ошибка чтения файла"));
        reader.readAsDataURL(file); // Преобразуем файл в Base64
      });
    },
    async generatedBase64List(files) {
      const maxTotalSizeKB = 700;
      let options = {
        maxSizeMB: 0.15,
        maxWidthOrHeight: 1024
      };

      // Разделяем файлы на изображения и PDF
      const imageFiles = files.filter(file => file.type.startsWith("image/"));
      const nonImageFiles = files.filter(
        file => !file.type.startsWith("image/")
      );

      const nonImageFilesSize = nonImageFiles.reduce(
        (total, file) => total + file.size,
        0
      );
      if (nonImageFilesSize > maxTotalSizeKB * 1024) {
        return [];
      }

      // Сжимаем только изображения
      let compressedImages = await Promise.all(
        imageFiles.map(file => imageCompression(file, options))
      );

      // Проверяем общий размер
      while (
        [...compressedImages, ...nonImageFiles].reduce(
          (total, file) => total + file.size,
          0
        ) >
        maxTotalSizeKB * 1024
      ) {
        // Уменьшаем параметры компрессии
        options.maxWidthOrHeight *= 0.9;

        // Пересжимаем изображения
        compressedImages = await Promise.all(
          imageFiles.map(file => imageCompression(file, options))
        );
      }

      // Комбинируем обработанные изображения и необработанные файлы
      const processedFiles = [...compressedImages, ...nonImageFiles];

      // Преобразуем файлы в Base64
      return await Promise.all(
        processedFiles.map(file => this.fileToBase64(file))
      );
    },
    addCheckToList() {
      if (
        this.file instanceof Blob &&
        (this.file.type.startsWith("image/") || this.file.type.includes("/pdf"))
      ) {
        this.checksList.push(this.file);
        this.file = null;
      } else {
        this.$noty.error("Файл не выбран или тип не поддерживаеться");
      }
    },
    deleteCheckFromList(fileIndex) {
      this.checksList.splice(fileIndex, 1);
    }
  },
  created() {
    InvoiceTypesServices.findMany().then(data => {
      console.log(data);
      this.types = data;
    });
  }
};
</script>

<style scoped></style>
